<template>
    <div class="search-wrapper">
        <header class="logo-button">
            <div class="logo">
                <a target="_blank" href="https://www.onon.cn"><img src="../assets/images/logo.c1dceb55.png" alt="网站Logo" /></a>
            </div>
            <div class="product">
                <ul class="product-list">
                    <li v-for="(product, index) in productList" :key="index" :class="product.isSelected ? 'is-select' : ''">
                        <a class="product-details" :href="product.path">
                            <div class="product-info" :class="product.free ? 'free' : ''">
                                <img :src="product.icon" class="product-icon" :class="product.isSelected ? 'proof-active' : ''" />
                                <p class="product-name" :style="product.isSelected ? 'color: #3ec387;' : ''">{{ product.name }}</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <Poptip placement="bottom-end" width="200">
                <Button type="text" class="poptip-button">
                    <Icon type="ios-apps" />
                </Button>
                <template #content>
                    <div class="poptip-product">
                        <ul class="product-list">
                            <li v-for="(product, index) in productList" :key="index" :class="product.isSelected ? 'li-active' : ''">
                                <a class="product-details" :href="product.path">
                                    <div class="product-info">
                                        <img :src="product.icon" class="product-icon" :class="product.isSelected ? 'proof-active' : ''" />
                                        <p class="product-name">{{ product.name }} {{ product.free ? '(限免)' : '' }}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </template>
            </Poptip>
            <Button type="dashed" shape="circle" @click="feedbackDrawer = true" style="margin-right: 20px" class="feedback-btn">
                <Icon type="ios-paper-outline" />
                建议反馈
            </Button>
            <Divider type="vertical" style="margin: 0 20px" />
            <Button @click="logIn()" type="primary" shape="circle" v-if="!login" style="margin-right: 20px">
                <Icon type="md-log-in" />
                登录
            </Button>
            <div v-if="login" style="position: relative" v-on:mouseenter="userListShow = true" v-on:mouseleave="userListShow = false">
                <Poptip placement="bottom-end" width="260">
                    <div class="user" @click="updateUserDetails">
                        <img class="user-svip-icon" src="../assets/images/user015.svg" v-if="user.isSvip == 1" />
                        <img src="../assets/images/头像.png" alt="用户默认头像" :style="user.isSvip == 1 ? 'border: 2px solid #ffde58;' : ''" v-if="user.avatar == null || user.avatar == ''" />
                        <img :src="user.avatar" :style="user.isSvip == 1 ? 'border: 2px solid #ffde58;' : ''" v-else />
                        <span class="user-nickname onon">{{ user.nickname }}</span>
                        <Icon type="ios-arrow-down" style="font-size: 14px" />
                    </div>
                    <template #content>
                        <div class="user-list">
                            <div class="user-name onon">
                                <span
                                    >{{ user.nickname }}
                                    <a href="https://dashboard.onon.cn/IndividualCenter">
                                        <Icon type="md-create" style="cursor: pointer; color: #5c6b77; margin-left: 3px" />
                                    </a>
                                </span>
                                <span style="font-size: 12px">会员:{{ user.svipExpireDate }} 到期</span>
                            </div>
                            <div class="user-service">
                                <span class="service-remaining">剩余资源(字符)</span>
                                <Progress :percent="percent" stroke-width="12" stroke-color="#3ec387" />
                                <span class="service-remaining-value">{{ formatNumber(ServiceDetails) }} / {{ formatNumber(ServiceTotalDetails) }}</span>
                            </div>
                            <div class="service-tab">
                                <div class="product-item">
                                    <a href="https://dashboard.onon.cn/my">
                                        <img src="../assets/images/wodeziyuan.svg" />
                                        我的资源
                                    </a>
                                </div>
                                <div class="product-item">
                                    <a href="https://dashboard.onon.cn/OrderManagement">
                                        <img src="../assets/images/dingdan.svg" />
                                        订单管理
                                    </a>
                                </div>
                                <div class="product-item">
                                    <a href="https://dashboard.onon.cn/InvoiceManagement">
                                        <img src="../assets/images/fapiao.svg" />
                                        发票管理
                                    </a>
                                </div>
                                <div class="product-item">
                                    <a href="https://dashboard.onon.cn/IndividualCenter">
                                        <img src="../assets/images/user.svg" />
                                        个人中心
                                    </a>
                                </div>
                            </div>
                            <Divider type="horizontal" />
                            <div class="log-button">
                                <Tooltip content="全站退出登录" placement="left">
                                    <span @click="logOut()">
                                        <Icon type="md-log-in" />
                                        退出登录
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </template>
                </Poptip>
            </div>
            <Drawer title="建议反馈" :closable="true" v-model="feedbackDrawer" width="400">
                <!-- 建议反馈 -->
                <div class="card">
                    <span class="phone">技术服务微信群</span>
                    <img src="https://onon-guanwang.oss-cn-beijing.aliyuncs.com/wcg/wcg.png" alt="" />
                </div>
                <div class="card">
                    <span class="pc">建议反馈</span>
                    <a href="https://wj.qq.com/s2/14682602/3919/" target="_blank">
                        <span><Icon type="md-open" /> Onon校对建议反馈</span>
                    </a>
                </div>
            </Drawer>
        </header>
        <Modal v-model="serviceModal" width="360">
            <template #header>
                <p style="color: #f60; text-align: center">
                    <Icon type="ios-information-circle"></Icon>
                    <span>{{ serviceModalTile }}</span>
                </p>
            </template>
            <div style="text-align: center">
                <p>您购买的校对服务包已用尽或已过期，请重新购买以正常使用校对服务！</p>
            </div>
            <template #footer>
                <a href="https://dashboard.onon.cn/OrdinaryUser?svid=3" style="color: inherit; display: inline-block; width: 100%">
                    <Button type="primary" size="large" shape="circle" long> 去购买 </Button>
                </a>
            </template>
        </Modal>
        <Modal v-model="loginModal" width="360">
            <template #header>
                <p style="color: #f60; text-align: center">
                    <Icon type="ios-information-circle"></Icon>
                    <span>登录已失效</span>
                </p>
            </template>
            <div style="text-align: center">
                <p>您的登录已失效，请重新登录！</p>
            </div>
            <template #footer>
                <Button @click="logIn()" type="primary" size="large" shape="circle" long> 登录 </Button>
            </template>
        </Modal>
        <div class="discount-img" v-if="pcImg != null && pcImg != ''">
            <a :href="href">
                <img :src="pcImg" />
            </a>
        </div>
        <div class="search" :style="{ margin: pcImg != null && pcImg != '' ? '5px auto 5px' : '35px auto 5px', height: pcImg != null && pcImg != '' ? 'calc(100vh - 178px)' : 'calc(100vh - 110px)' }">
            <div class="button-group">
                <Tooltip content="返回" placement="bottom">
                    <Button type="primary" @click="backModal = true" v-show="isBack" class="back" icon="md-swap"></Button>
                </Tooltip>
                <Modal v-model="backModal" width="360">
                    <template #header>
                        <p style="color: #f60; text-align: center">
                            <Icon type="ios-information-circle"></Icon>
                            <span>请注意</span>
                        </p>
                    </template>
                    <div style="text-align: center">
                        <p>确定返回到原始文本吗？</p>
                    </div>
                    <template #footer>
                        <Button type="error" size="large" long :loading="modal_loading" @click="backBefore()">确定</Button>
                    </template>
                </Modal>
                <Tooltip content="复制全部" placement="bottom">
                    <Button type="primary" @click="copyText()" v-show="isCopy" class="copy" icon="md-albums"></Button>
                </Tooltip>
                <Tooltip content="清除" placement="bottom">
                    <Button type="primary" @click="deleteModal = true" v-show="isDelete" class="delete" icon="md-close"></Button>
                </Tooltip>
                <Modal v-model="deleteModal" width="360">
                    <template #header>
                        <p style="color: #f60; text-align: center">
                            <Icon type="ios-information-circle"></Icon>
                            <span>请注意</span>
                        </p>
                    </template>
                    <div style="text-align: center">
                        <p>确定清空输入框内的全部文本吗？</p>
                    </div>
                    <template #footer>
                        <Button type="error" size="large" long :loading="modal_loading" @click="deleteText()">确定</Button>
                    </template>
                </Modal>
            </div>
            <span class="text-code" v-show="textCode != ''">文本编码 : {{ textCode }} 共 {{ wordNumber }} 字符</span>
            <div class="word-container">
                <img class="word-container-img" src="../assets/images/on010351.7482eecf.png" alt="蒙古语语法校对软件" v-show="imageShow" />
                <div
                    class="mgl onon"
                    :class="font == 1 ? 'galt' : ''"
                    id="div"
                    contenteditable="true"
                    @input="contentChange"
                    @paste="clip"
                    placeholder="ᠬᠠᠷᠭᠤᠭᠤᠯᠬᠤ ᠠᠭᠤᠯᠭ᠎ᠠ ᠪᠠᠨ ᠡᠨᠳᠡ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ᠃᠃᠃"
                ></div>
                <div class="wordList" v-show="open" :style="{ left: positionX + 'px', top: positionY + 'px' }" v-on:mouseenter="wordListmouseEnter" v-on:mouseleave="wordListmouseLeave">
                    <div class="word-spell mgl onondev" :class="font == 1 ? 'galt' : ''">
                        <li class="high" v-for="(high, index) in highList" :key="index" @click="setWord(hoverSpan.elm, high, hoverSpan.index)">
                            {{ high }}
                        </li>
                        <li class="new-word" :class="hoverSpan.wordListSpanClass" v-for="(value, index) in wordList" :key="index" @click="setWord(hoverSpan.elm, value, hoverSpan.index)">
                            {{ value }}
                        </li>
                    </div>
                    <div class="ignore">
                        <Tooltip content="忽略" placement="bottom">
                            <Button shape="circle" icon="md-trash" @click="ignore(hoverSpan.elm, hoverSpan.index, '')"></Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div class="work-area">
                <div class="button-list">
                    <div class="feature-button">
                        <Button id="proofread" type="primary" shape="circle" :loading="loading" @click="getName()">
                            <span v-if="!loading">
                                <Icon type="md-barcode" />
                                立即校对
                            </span>
                            <span v-else>校对中...</span>
                        </Button>
                        <Button type="primary" shape="circle" @click="undo" :disabled="!ignored.length > 0">
                            <Icon type="md-undo" />
                            撤销
                        </Button>
                    </div>
                    <div class="select-dic">
                        <RadioGroup v-model="dictname_">
                            词典选择:
                            <Radio label="ONON">
                                <span>Onon 词库</span>
                            </Radio>
                            <Radio label="ZZFQT">
                                <span>正字法全本</span>
                            </Radio>
                        </RadioGroup>
                    </div>
                    <div class="word-button">
                        <div class="all-button">
                            <Button @click="sortClick('all')" class="all" :class="{ active: errType.all }" type="text"
                                >全部提示 <CountUp :end="allspellCount" :duration="1" ref="count" v-font="18"
                            /></Button>
                        </div>
                        <div class="sort-button">
                            <!-- <Button @click="sortClick('spellrepXm')" class="spell-rep-xm" :class="{'active':errType.spellrepXm}" type="text">高概率 <CountUp :end="spellrepXmCount" :duration="1" ref="count" v-font="14" /></Button> -->
                            <Button @click="sortClick('spellErrHigh')" class="spell-err-high" :class="{ active: errType.spellErrHigh }" type="text"
                                >高概率 <CountUp :end="spellErrHighCount" :duration="1" ref="count" v-font="14"
                            /></Button>
                            <Button @click="sortClick('spellErr')" class="spell-err" :class="{ active: errType.spellErr }" type="text"
                                >错别词 <CountUp :end="spellErrCount" :duration="1" ref="count" v-font="14"
                            /></Button>
                            <!-- <Button @click="sortClick('spellRep')" class="spell-rep" :class="{'active':errType.spellRep}" type="text">提示 <CountUp :end="spellRepCount" :duration="1" ref="count" v-font="14" /></Button> -->
                            <Transition name="fade" mode="in-out" appear>
                                <Button v-show="font == 0" @click="sortClick('spellwrdXmyin')" class="spell-wrd-xmyin" :class="{ active: errType.spellwrdXmyin }" type="text"
                                    >同音同形 <CountUp :end="spellwrdXmyinCount" :duration="1" ref="count" v-font="14"
                                /></Button>
                            </Transition>
                            <Button @click="sortClick('spellwrdXm')" class="spell-wrd-xm" :class="{ active: errType.spellwrdXm }" type="text"
                                >同形异音 <CountUp :end="spellwrdXmCount" :duration="1" ref="count" v-font="14"
                            /></Button>
                        </div>
                    </div>
                </div>
                <div class="word-tip">
                    <div class="word-tip-img" v-if="allspellCount == 0">
                        <img src="../assets/images/onon-user02.png" alt="免费蒙古文拼写检查工具" />
                    </div>
                    <TransitionGroup name="trans" tag="ul" class="word-tip-ul" v-else>
                        <li
                            v-for="(span, index) in spanEle"
                            :key="span.dataset.id"
                            v-show="(errType[liClassName(span)] || errType['all']) && !isIgnored(span)"
                            :class="liClassName(span)"
                            :data-id="span.dataset.id"
                            v-on:mouseenter="handleMouseEnter"
                            v-on:mouseleave="handleMouseLeave"
                        >
                            <div class="word-group onondev" :class="font == 1 ? 'galt' : ''">
                                <span class="mgl" v-if="spanAttribute(span)?.data?.old">{{ spanAttribute(span)?.data?.old }}</span>
                                <Icon v-if="spanAttribute(span)?.data?.slst != '' || spanAttribute(span)?.data?.high != ''" type="ios-arrow-down" />
                                <span
                                    @click="setWord(span, spanAttribute(span)?.data?.high, index)"
                                    class="mgl"
                                    :class="{ 'high-err': spanAttribute(span)?.data?.high }"
                                    v-if="spanAttribute(span)?.data?.high != ''"
                                    >{{ spanAttribute(span)?.data?.high }}</span
                                >
                                <span @click="setWord(span, value, index)" class="mgl" :class="slstSpanClassName(span)" v-for="(value, index) in spanAttribute(span)?.data?.slst" :key="index">{{
                                    value
                                }}</span>
                            </div>
                            <div class="btn">
                                <Tooltip content="忽略" placement="bottom">
                                    <Button shape="circle" icon="md-trash" @click="ignore(span, index, '')"></Button>
                                </Tooltip>
                            </div>
                        </li>
                    </TransitionGroup>
                </div>
            </div>
            <Spin fix :show="spinShow">
                <Icon type="ios-loading" size="38" class="demo-spin-icon-load"></Icon>
                <div style="font-size: 20px">校对中，请稍后</div>
            </Spin>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import font from '@/assets/fonts/fontStyle.css';
    import $ from 'jquery';
    import { eventNames, off } from 'process';
    import cookie from 'js-cookie';

    export default {
        data() {
            return {
                user: {}, //用户信息
                ServiceDetails: '', //用户服务包剩余流量
                ServiceTotalDetails: '', //用户服务包总流量
                userListShow: false, //用户信息展示
                wordNumber: 0, //文本框字数
                // form: {
                //     code: '',
                //     response_type: 'code',
                //     grant_type: 'authorization_code',
                //     client_id: '1777507618860961793',
                //     client_secret: '0f77ac2fbbd249dfb3f5fd1714196d7b',
                //     redirect_uri: 'http://localhost:8080/'
                // },
                form: {
                    code: '',
                    response_type: 'code',
                    grant_type: 'authorization_code',
                    client_id: '1729390517613105152',
                    client_secret: 'd9914cf630f74d81825ada82e27186b5',
                    redirect_uri: 'http://spell.onon.cn'
                },
                login: true, //登录状态
                token: '', //登录后获取的token
                serviceModal: false, //购买服务弹窗
                loginModal: false,
                serviceModalTile: '',
                dictname: '',
                dictname_: 'ONON',
                errType: {
                    all: true,
                    spellErr: false,
                    spellErrHigh: false,
                    spellwrdXmyin: false,
                    spellwrdXm: false
                },
                spanEle: [], //后台span元素集合
                showClasses: [], //后台span元素集合
                allspellCount: 0, //分类span全部数量
                spellErr: [], //根据class分类存储
                spellErrCount: 0, //分类span元素数量
                spellErrHigh: [],
                spellErrHighCount: 0,
                // spellRep: [],
                // spellRepCount: 0,
                spellwrdXmyin: [],
                spellwrdXmyinCount: 0,
                // spellrepXm: [],
                // spellrepXmCount: 0,
                spellwrdXm: [],
                spellwrdXmCount: 0,
                ignored: [],
                wordList: [],
                highList: [],
                sameAttribute: [],
                carouselValue: 0, //轮播图
                drawerValue1: false, //Onon应用抽屉第一级
                drawerValue2: false, //Onon应用抽屉第二级
                newWord: '', //候选词
                oldWord: '', //候选词:
                oldText: '', //校对前文字
                font: 0,
                textCode: '', //文本编码
                activeWord: '', //候选词模块选中
                uuid: '', //返回后台参数
                positionX: '', //候选词模块坐标
                positionY: '', //候选词模块坐标
                isBack: false, //取消按钮
                isCopy: false, //复制按钮
                isDelete: false, //清除按钮
                imageShow: true,
                backModal: false, //返回校对前Modal
                deleteModal: false, //清空校对前Modal
                feedbackDrawer: false, //建议反馈Drawer
                modal_loading: false, //返回校对前Modal loading
                open: false, //候选词模块
                openFalse: false,
                hoverSpan: {},
                openStatus: false, //Onon应用
                loading: false, //校对按钮
                spinShow: false, //校对遮罩
                liTimer: null, //候选词区域li元素鼠标进入延迟
                spanTimer: null, //校对区域span元素鼠标进入延迟
                timeoutId: null, //wordList组件定时器
                carouselItem: [
                    //轮播图item
                    {
                        itemHref: 'http://ime.onon.cn/',
                        itemImg: require('@/assets/images/ime.f5906eed.gif'),
                        itemAlt: 'Onon输入法轮播图图片'
                    },
                    {
                        itemHref: 'http://office.onon.cn/',
                        itemImg: require('@/assets/images/office.335d2ac4.png'),
                        itemAlt: 'Onon Office轮播图图片'
                    },
                    {
                        itemHref: 'http://ime.onon.cn/',
                        itemImg: require('@/assets/images/中文-ime-mac.d7bc863c.jpg'),
                        itemAlt: 'Onon输入法Mac版轮播图图片'
                    },
                    {
                        itemHref: 'http://map.onon.cn/',
                        itemImg: require('@/assets/images/地图.834f2df6.jpg'),
                        itemAlt: 'Onon地图轮播图图片'
                    },
                    {
                        itemHref: 'https://doc.onon.cn/',
                        itemImg: require('@/assets/images/文库.49885cbe.png'),
                        itemAlt: 'Onon文库轮播图图片'
                    }
                ],
                firstPasteLength: 0, // 添加一个变量来存储第一次粘贴的文本长度
                preList: [], //检测蒙古文编码空格时存储原有的文本节点
                productList: [
                    {
                        name: '智能翻译',
                        path: 'https://mt.onon.cn',
                        icon: require('../assets/images/language.svg'),
                        isSelected: false,
                        free: false
                    },
                    {
                        name: '文字校对',
                        path: 'http://spell.onon.cn',
                        icon: require('../assets/images/proof.svg'),
                        isSelected: true,
                        free: false
                    },
                    {
                        name: '图片识别',
                        path: 'http://ocr.onon.cn',
                        icon: require('../assets/images/ocr.svg'),
                        isSelected: false,
                        free: false
                    },
                    {
                        name: '编码转换',
                        path: 'https://mt.onon.cn/codeconvert',
                        icon: require('../assets/images/code.svg'),
                        isSelected: false,
                        free: true
                    },
                    {
                        name: '蒙古文转换',
                        path: 'https://mt.onon.cn/convert',
                        icon: require('../assets/images/language-km.svg'),
                        isSelected: false,
                        free: true
                    }
                ], //导航栏产品
                deviceTypeModal: false,
                percent: 0,
                //618活动
                activateOpen: false,
                pcImg: '',
                href: ''
            };
        },
        methods: {
            // isMobile () {
            //     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            //     return flag
            // },
            updateUserDetails() {
                this.getServiceDetails();
            },
            baifenbi(can, total) {
                if (total == 0) {
                    this.percent = 0;
                } else {
                    let percentage = (can / total) * 100;
                    percentage = percentage.toFixed(2);
                    this.percent = percentage;
                }
            },
            formatNumber(value) {
                if (value >= 10000) {
                    const result = (value / 10000).toFixed(2);
                    return result.replace(/\.?0*$/, '') + '万';
                } else {
                    return value.toString();
                }
            },
            // logIn() {
            //     window.location.href = 'https://user.onon.cn/authorize?client_id=' + '1777507618860961793' + '&redirect_uri=' + 'http://localhost:8080/' + '&state=1234' + '&response_type=' + 'code';
            // },
            logIn() {
                window.location.href = 'https://user.onon.cn/authorize?client_id=' + '1729390517613105152' + '&redirect_uri=' + 'http://spell.onon.cn' + '&state=1234' + '&response_type=' + 'code';
            },
            //退出登录
            logOut() {
                let url = 'openapi/auth/logouts';
                let access_token = window.localStorage.getItem('access_token');
                axios.get('https://user.onon.cn/onon/' + url + '?access_token=' + access_token).then((res) => {
                    if (res.data.code === 200 || res.data.code === 40001) {
                        // cookie.remove('SSOSYSaccessToken',{
                        //     domain: 'onon.cn',
                        //     path: '/'
                        // })
                        const msg = this.$Message.loading({
                            content: '正在退出登录...',
                            duration: 0
                        });
                        setTimeout(msg, 2800);
                        setTimeout(() => {
                            this.user = {};
                            this.login = false;
                            this.$Message.success('退出成功');
                        }, 3000);
                        window.localStorage.clear();
                    } else {
                        this.$Message.error(res.data.message);
                    }
                });
            },
            // Onon应用右侧抽屉
            drawer(e) {
                (this.drawerValue2 = true), (this.clickApp = e);
            },
            toLoading() {
                this.loading = true;
            },
            // 监听用户粘贴文本编码格式
            clip(e) {
                e.preventDefault();
                if (this.imageShow) {
                    this.imageShow = false;
                }
                let text = e.clipboardData.getData('text');
                if (/[\u4E00-\u9FA5]/.test(text)) {
                    // 提取汉字并计算字符数
                    let chineseCharacters = text.match(/[\u4E00-\u9FA5]/g);
                    let chineseCharacterCount = chineseCharacters ? chineseCharacters.length : 0;
                    // console.log(chineseCharacterCount);
                    // 总字符数减去汉字字符数
                    this.wordNumber = text.length - chineseCharacterCount * 2;
                    // console.log(this.wordNumber);
                    this.$Notice.info({
                        title: '提醒',
                        desc: '检测到您粘贴的文本中有非蒙古文字符，已为您减去此字符。',
                        duration: 7
                    });
                } else {
                    // console.log('else');
                    this.wordNumber = text.length;
                }
                let selectText = window.getSelection().toString();
                let allText = document.getElementById('div').innerText;
                let lines = text.split(/\r\n|\r|\n/);
                let indentedText = lines
                    .map((line) => {
                        if (line == '') {
                            // return `<div class="paragraph"><br></div>`
                            return;
                        }
                        return `<div class="paragraph">${line}</div>`;
                    })
                    .join('');
                document.execCommand('insertText', false, text);
                // document.getElementById("div").innerHTML = indentedText;
                if (allText.length - selectText.length < text.length) {
                    let from = new FormData();
                    from.append('str', text);
                    // let url = '/api'
                    let url = 'https://t.onon.cn/api/Conversion/FormCodeType';
                    axios
                        .post(url, from)
                        .then((res) => {
                            if (res.data.Success == 1 && res.data.Result == '民委共享工程标准') {
                                this.font = 1;
                                this.textCode = '民委共享工程标准';
                            } else if (res.data.Success == 1 && res.data.Result == '标准') {
                                this.font = 2;
                                this.textCode = '标准2010';
                            } else {
                                this.font = 0;
                                this.textCode = '蒙科立编码';
                            }
                        })
                        .catch(function (error) {
                            this.$Message.warning({
                                title: '提醒',
                                desc: error
                            });
                        });
                }
            },
            // 监听文本框
            contentChange(event) {
                event.preventDefault();
                // 获取输入文本的第一个字符
                let firstChar = event.target.innerText.charAt(0);
                // 判断第一个字符是汉文还是蒙古文
                if (/[\u4E00-\u9FA5]/.test(firstChar)) {
                    // 如果是汉文，显示提示让用户输入蒙古文
                    this.$Message.info('请输入蒙古文进行校对！');
                    //删除输入的文字
                    event.target.innerText = '';
                }
                this.wordNumber = event.target.innerText.length;
                // if(event.inputType === 'insertText' || event.inputType === 'deleteContentBackward'){
                //     this.ignored.push({})
                // }
                // console.log(this.ignored);
                if (Array.isArray(this.spanEle) && this.spanEle.length > 0) {
                    let selection = window.getSelection();
                    let range = selection.getRangeAt(0);
                    // 获取光标所在的文本节点
                    let node = range.startContainer.parentElement;
                    let index = this.spanEle.indexOf(node);
                    if (node.nodeName == 'SPAN' && index != -1) {
                        this.ignore(node, index, 'null');
                    }
                }
                if (event.target.innerText == '') {
                    this.isBack = false;
                    this.isCopy = false;
                    this.isDelete = false;
                    this.imageShow = true;
                    $('#proofread').removeAttr('disabled');
                    this.open = false;
                    this.allspellCount = 0;
                    this.spellErrCount = 0;
                    this.spellErrHighCount = 0;
                    // this.spellRepCount = 0
                    this.spellwrdXmyinCount = 0;
                    // this.spellrepXmCount = 0
                    this.spellwrdXmCount = 0;
                    this.spellErr = [];
                    this.spellErrHigh = [];
                    // this.spellRep =  []
                    this.spellwrdXmyin = [];
                    // this.spellrepXm =  []
                    this.spellwrdXm = [];
                    this.ignored = [];
                    this.preList = [];
                    this.textCode = '';
                    this.wordNumber = 0;
                } else {
                    this.isCopy = true;
                    this.isDelete = true;
                    this.imageShow = false;
                }
            },
            // 复制全部按钮监听
            copyText() {
                let text = document.getElementById('div').innerText.replace(/\n\n/g, '\n').replace(/ /g, ' ');
                if (text !== '') {
                    if (!navigator.clipboard && window.isSecureContext) {
                        this.$Message.info('文本已复制到剪贴板！');
                        return navigator.clipboard.writeText(text);
                    } else {
                        // if (!document.execCommand('copy')) return Promise.reject()
                        // const textArea = document.createElement('textarea')
                        // textArea.style.position = 'fixed'
                        // textArea.style.top = textArea.style.left = '-100vh'
                        // textArea.style.opacity = '0'
                        // textArea.value = text
                        // document.body.appendChild(textArea)
                        // textArea.focus()
                        // textArea.select()
                        // this.$Message.info('文本已复制到剪贴板！')
                        // return new Promise((resolve, reject) => {
                        //     document.execCommand('copy') ? resolve() : reject()
                        //     textArea.remove()
                        // })
                        const textArea = document.createElement('textarea');
                        textArea.style.position = 'fixed';
                        textArea.style.top = textArea.style.left = '-100vh';
                        textArea.style.opacity = '0';
                        textArea.value = text;
                        document.body.appendChild(textArea);
                        textArea.focus();
                        textArea.select();
                        this.$Message.info('文本已复制到剪贴板！');
                        return new Promise((resolve, reject) => {
                            try {
                                document.execCommand('copy') ? resolve() : reject();
                            } catch (err) {
                                reject(err);
                            } finally {
                                textArea.remove();
                            }
                        });
                    }
                }
            },
            // 清空按钮监听
            deleteText() {
                document.getElementById('div').innerHTML = '';
                this.oldText = '';
                this.open = false;
                $('#proofread').removeAttr('disabled');
                this.isBack = false;
                this.isCopy = false;
                this.isDelete = false;
                this.deleteModal = false;
                this.imageShow = true;
                this.allspellCount = 0;
                this.spellErrCount = 0;
                this.spellErrHighCount = 0;
                // this.spellRepCount = 0
                this.spellwrdXmyinCount = 0;
                // this.spellrepXmCount = 0
                this.spellwrdXmCount = 0;
                this.spellErr = [];
                this.spellErrHigh = [];
                // this.spellRep =  []
                this.spellwrdXmyin = [];
                // this.spellrepXm =  []
                this.spellwrdXm = [];
                this.ignored = [];
                this.preList = [];
                this.textCode = '';
                this.wordNumber = 0;
                this.$Message.success('已全部清空！');
            },
            // 返回按钮监听
            backBefore() {
                this.modal_loading = true;
                setTimeout(() => {
                    this.modal_loading = false;
                    this.backModal = false;
                    document.getElementById('div').innerText = this.oldText;
                    $('#proofread').removeAttr('disabled');
                    this.open = false;
                    this.isBack = false;
                    this.allspellCount = 0;
                    this.spellErrCount = 0;
                    this.spellErrHighCount = 0;
                    // this.spellRepCount = 0
                    this.spellwrdXmyinCount = 0;
                    // this.spellrepXmCount = 0
                    this.spellwrdXmCount = 0;
                    this.spellErr = [];
                    this.spellErrHigh = [];
                    // this.spellRep =  []
                    this.spellwrdXmyin = [];
                    // this.spellrepXm =  []
                    this.spellwrdXm = [];
                    this.ignored = [];
                    this.preList = [];
                    this.oldText = '';
                    this.$Message.success('已改回到原始文本状态！');
                }, 1000);
            },
            // 监听wordList弹出组件鼠标进入
            wordListmouseEnter() {
                clearTimeout(this.timeoutId);
                this.hoverSpan.elm.style.transition = 'all .4s ease';
                switch (this.hoverSpan.elm.className) {
                    case 'spell-err':
                        this.hoverSpan.elm.style.backgroundColor = 'rgba(153,0,0,.26)';
                        break;
                    case 'spell-err-high':
                        this.hoverSpan.elm.style.backgroundColor = 'rgba(0, 153, 255,.26)';
                        break;
                    case 'spell-wrd-xmyin':
                        this.hoverSpan.elm.style.backgroundColor = 'rgba(0, 153, 0,.26)';
                        break;
                    case 'spell-wrd-xm':
                        this.hoverSpan.elm.style.backgroundColor = 'rgba(255, 153, 0,.26)';
                        break;
                    default:
                        break;
                }
                switch (this.hoverSpan.liEle.className) {
                    case 'spellErr':
                        this.hoverSpan.liEle.classList.add('spellErr-hover');
                        this.hoverSpan.liEle.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                        break;
                    case 'spellErrHigh':
                        this.hoverSpan.liEle.classList.add('spellErrHigh-hover');
                        this.hoverSpan.liEle.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                        break;
                    case 'spellwrdXmyin':
                        this.hoverSpan.liEle.classList.add('spellwrdXmyin-hover');
                        this.hoverSpan.liEle.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                        break;
                    case 'spellwrdXm':
                        this.hoverSpan.liEle.classList.add('spellwrdXm-hover');
                        this.hoverSpan.liEle.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                        break;
                    default:
                        break;
                }
            },
            // 监听wordList弹出组件鼠标离开
            wordListmouseLeave() {
                // this.open = false
                this.hoverSpan.elm.style.removeProperty('background-color');
                this.hoverSpan.elm.style.removeProperty('transition');
                switch (this.hoverSpan.liEle.classList[0]) {
                    case 'spellErr':
                        this.hoverSpan.liEle.classList.remove('spellErr-hover');
                        break;
                    case 'spellErrHigh':
                        this.hoverSpan.liEle.classList.remove('spellErrHigh-hover');
                        break;
                    case 'spellwrdXmyin':
                        this.hoverSpan.liEle.classList.remove('spellwrdXmyin-hover');
                        break;
                    case 'spellwrdXm':
                        this.hoverSpan.liEle.classList.remove('spellwrdXm-hover');
                        break;
                    default:
                        break;
                }
            },
            // 校对逻辑函数
            getName() {
                let name_ = document.getElementById('div').innerText;
                // console.log(name_);
                if (this.login) {
                    if (name_ == '') {
                        this.$Message.warning('请输入文本再进行校对！');
                        this.loading = false;
                        this.spinShow = false;
                    } else {
                        let _that = this;
                        if (_that.oldText === name_) {
                            // console.log(111111);
                            this.$Message.warning('此文本已经校对完成！');
                            return;
                        }
                        let access_token = window.localStorage.getItem('access_token');
                        if (access_token) {
                            _that.spinShow = true;
                            _that.toLoading();
                            // 新接口
                            _that.oldText = name_;
                            let url = 'http://spellapi.onon.cn:8080/jna/proofread_v1';
                            // let url = 'http://11.0.0.176:8081/jna/proofread';
                            let data = { content: name_, dictName: _that.dictname_, accessToken: access_token, proofreadtype: '2', resulttype: '2' };
                            axios
                                .post(url, data)
                                .then((res) => {
                                    if (res.status == 200 && res.data.code == 200 && res.data.result) {
                                        // console.log(res.data.result);
                                        _that.spinShow = false;
                                        _that.loading = false;
                                        _that.isBack = true;
                                        // $("#proofread").attr('disabled',true)
                                        let div = document.getElementById('div');
                                        div.innerHTML = res.data.result.replace(/<br>([^<]+)<br>/g, '<div class="paragraph">$1</div>');
                                        // div.innerHTML = res.data.result.replace(/<br>/g,'<div class="paragraph"><br></div>')
                                        // div.textContent = res.data.result;
                                        _that.spanEle = div.querySelectorAll('span');
                                        // 民委编码下
                                        if (_that.font == 1 || _that.font == 2) {
                                            //遍历后台数据，删除spell-wrd-xmyin
                                            div.querySelectorAll('.spell-wrd-xmyin').forEach((element) => {
                                                element.className = 'spell-wrd-xmyin-none';
                                            });
                                            //遍历数组，删除spell-wrd-xmyin
                                            const spanEles = [..._that.spanEle];
                                            for (let i = 0; i < spanEles.length; i++) {
                                                if (spanEles[i].className == 'spell-wrd-xmyin-none') {
                                                    spanEles.splice(i, 1);
                                                    i--;
                                                }
                                            }
                                            _that.spanEle = spanEles;
                                        }
                                        for (let i = 0; i < _that.spanEle.length; i++) {
                                            _that.spanEle[i].dataset.id = i + 1;
                                            _that.spanEle[i].dataset.view = true;
                                            switch (_that.spanEle[i].className) {
                                                case 'spell-err':
                                                    if (_that.spanEle[i].hasAttribute('high-p-wrd')) {
                                                        _that.spellErrHigh.push(_that.spanEle[i]);
                                                        _that.spanEle[i].className = 'spell-err-high';
                                                        _that.spellErrHighCount++;
                                                        _that.allspellCount++;
                                                    } else {
                                                        _that.spellErr.push(_that.spanEle[i]);
                                                        _that.spellErrCount++;
                                                        _that.allspellCount++;
                                                    }
                                                    break;
                                                case 'spell-wrd-xmyin':
                                                    _that.spellwrdXmyin.push(_that.spanEle[i]);
                                                    _that.spellwrdXmyinCount++;
                                                    _that.allspellCount++;
                                                    break;
                                                case 'spell-wrd-xm':
                                                    _that.spellwrdXm.push(_that.spanEle[i]);
                                                    _that.spellwrdXmCount++;
                                                    _that.allspellCount++;
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        $('#div span[data-view=true]').mouseenter(function (e) {
                                            _that.wordList = [];
                                            _that.highList = [];
                                            _that.spanEle = Array.from(_that.spanEle);
                                            e.stopPropagation();
                                            clearTimeout(_that.timeoutId);
                                            _that.open = false;
                                            let id = e.target.dataset.id;
                                            let li = document.querySelector(`li[data-id="${id}"]`);
                                            let spanClassName = '';
                                            switch (e.target.className) {
                                                case 'spell-err':
                                                    spanClassName = 'err';
                                                    break;
                                                case 'spell-wrd-xmyin':
                                                    spanClassName = 'wrd-xm-yin';
                                                    break;
                                                case 'spell-wrd-xm':
                                                    spanClassName = 'wrd-xm';
                                                    break;
                                                default:
                                                    break;
                                            }
                                            _that.hoverSpan = {
                                                elm: e.target,
                                                index: _that.spanEle.indexOf(e.target),
                                                liEle: li,
                                                wordListSpanClass: spanClassName
                                            };
                                            switch (e.target.className) {
                                                case 'spell-err':
                                                    if (e.target.getAttribute('slst-data')) {
                                                        _that.wordList = e.target.getAttribute('slst-data').split(',');
                                                    }
                                                    if (e.target.getAttribute('high-p-wrd')) {
                                                        _that.highList = e.target.getAttribute('high-p-wrd').split(',');
                                                    }
                                                    this.spanTimer = setTimeout(() => {
                                                        li.classList.add('spellErr-hover');
                                                        li.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                                                    }, 500);
                                                    break;
                                                case 'spell-err-high':
                                                    if (e.target.getAttribute('high-p-wrd')) {
                                                        _that.highList = e.target.getAttribute('high-p-wrd').split(',');
                                                    }
                                                    this.spanTimer = setTimeout(() => {
                                                        li.classList.add('spellErrHigh-hover');
                                                        li.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                                                    }, 500);
                                                    break;
                                                // case 'spell-rep':
                                                //     this.spanTimer = setTimeout(()=> {
                                                //         li.classList.add('spellRep-hover');
                                                //         li.scrollIntoView({behavior: 'smooth' ,inline : 'center'})
                                                //     },500)
                                                // break;
                                                case 'spell-wrd-xmyin':
                                                    if (e.target.getAttribute('slst-data')) {
                                                        _that.wordList = e.target.getAttribute('slst-data').split(',');
                                                    }
                                                    if (e.target.getAttribute('high-p-wrd')) {
                                                        _that.highList = e.target.getAttribute('high-p-wrd').split(',');
                                                    }
                                                    this.spanTimer = setTimeout(() => {
                                                        li.classList.add('spellwrdXmyin-hover');
                                                        li.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                                                    }, 500);
                                                    break;
                                                // case 'spell-rep-xm':
                                                //     _that.wordList = e.target.getAttribute('slst-data').split(',')
                                                //     this.spanTimer = setTimeout(()=> {
                                                //         li.classList.add('spellrepXm-hover');
                                                //         li.scrollIntoView({behavior: 'smooth' ,inline : 'center'})
                                                //     },500)
                                                // break;
                                                case 'spell-wrd-xm':
                                                    if (e.target.getAttribute('slst-data')) {
                                                        _that.wordList = e.target.getAttribute('slst-data').split(',');
                                                    }
                                                    if (e.target.getAttribute('high-p-wrd')) {
                                                        _that.highList = e.target.getAttribute('high-p-wrd').split(',');
                                                    }
                                                    this.spanTimer = setTimeout(() => {
                                                        li.classList.add('spellwrdXm-hover');
                                                        li.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                                                    }, 500);
                                                    break;
                                                case 'spell-err-none':
                                                    _that.openFalse = true;
                                                    break;
                                                case 'spell-err-high-none':
                                                    _that.openFalse = true;
                                                    break;
                                                // case 'spell-rep-none':
                                                //     _that.openFalse = true
                                                // break;
                                                case 'spell-wrd-xmyin-none':
                                                    _that.openFalse = true;
                                                    break;
                                                // case 'spell-rep-xm-none':
                                                //     _that.openFalse = true
                                                // break;
                                                case 'spell-wrd-xm-none':
                                                    _that.openFalse = true;
                                                    break;
                                                default:
                                                    break;
                                            }
                                            if (li.children[0].scrollWidth > li.scrollWidth) {
                                                li.children[0].style.width = li.children[0].scrollWidth + 'px';
                                            }
                                            _that.positionX = e.target.offsetLeft + e.target.offsetWidth;
                                            _that.positionY = e.target.offsetTop - 10;
                                            let wordContainer = $('.word-container');
                                            let wordList = $('.wordList');
                                            _that.$nextTick(() => {
                                                let wordlistTimer = setTimeout(() => {
                                                    let wordContainerWidth = wordContainer[0].offsetWidth;
                                                    let wordContainerHeight = wordContainer[0].offsetHeight;
                                                    let wordContainerScrollLeft = wordContainer[0].scrollLeft;
                                                    let wordListLeft = wordList[0].offsetLeft;
                                                    let wordListTop = wordList[0].offsetTop;
                                                    let wordListWidth = wordList[0].offsetWidth;
                                                    let wordListHeight = wordList[0].offsetHeight;
                                                    if (wordListLeft + wordListWidth > wordContainerWidth + wordContainerScrollLeft) {
                                                        _that.positionX = wordListLeft - (wordListWidth + e.target.offsetWidth);
                                                    }
                                                    if (wordListHeight + wordListTop > wordContainerHeight) {
                                                        _that.positionY = wordListTop - 71;
                                                    }
                                                    if (wordListTop < 0) {
                                                        _that.positionY = wordListTop + 10;
                                                    }
                                                    clearTimeout(wordlistTimer);
                                                }, 10);
                                                if (_that.openFalse) {
                                                    _that.open = false;
                                                } else {
                                                    _that.open = true;
                                                }
                                            });
                                            _that.open = true;
                                        });
                                        $('#div span').mouseleave(function (e) {
                                            clearTimeout(this.spanTimer);
                                            e.stopPropagation();
                                            let id = e.target.dataset.id;
                                            let li = document.querySelector(`li[data-id="${id}"]`);
                                            switch (e.target.className) {
                                                case 'spell-err':
                                                    li.classList.remove('spellErr-hover');
                                                    break;
                                                case 'spell-err-high':
                                                    li.classList.remove('spellErrHigh-hover');
                                                    break;
                                                case 'spell-wrd-xmyin':
                                                    li.classList.remove('spellwrdXmyin-hover');
                                                    break;
                                                case 'spell-wrd-xm':
                                                    li.classList.remove('spellwrdXm-hover');
                                                    break;
                                                default:
                                                    break;
                                            }
                                            _that.timeoutId = setTimeout(() => {
                                                _that.open = false;
                                                _that.wordList = [];
                                                _that.highList = [];
                                            }, 500);
                                            // _that.open = false
                                            _that.openFalse = false;
                                            li.children[0].style.width = 52 + 'px';
                                        });
                                        _that.sortFor();
                                    } else if (res.data.code == 40001) {
                                        this.$Message.error(res.data.message);
                                        window.localStorage.clear();
                                        _that.clearRouter();
                                        _that.login = false;
                                        _that.spinShow = false;
                                        _that.loading = false;
                                    } else if (res.data.code == 30001) {
                                        _that.serviceModal = true;
                                        _that.serviceModalTile = res.data.message;
                                        _that.spinShow = false;
                                        $('#proofread').removeAttr('disabled');
                                        _that.loading = false;
                                        // this.$Notice.warning({
                                        //     title: res.data.message,
                                        //     duration: 8,
                                        //     render: h => {
                                        //         return h('span', [
                                        //             h('a',{
                                        //                 href: ' https://dashboard.onon.cn/OrdinaryUser?svid=3',
                                        //                 target: '_blank'
                                        //             }, '去购买')
                                        //         ])
                                        //     }
                                        // });
                                        // this.$Message.error(res.data.message)
                                        // _that.spinShow = false
                                        // $("#proofread").removeAttr('disabled')
                                        // _that.loading = false
                                    } else {
                                        this.$Message.error(res.data.message);
                                    }
                                })
                                .catch((error) => {
                                    this.$Message.error('校对服务出错！');
                                    _that.spinShow = false;
                                    _that.loading = false;
                                    $('#proofread').removeAttr('disabled');
                                });
                        } else {
                            this.loginModal = true;
                        }
                    }
                } else {
                    this.loginModal = true;
                }
            },
            isIgnored(span) {
                this.ignored.forEach((element) => {
                    if (element.element == span) {
                        return true;
                    }
                });
                return false;
            },
            // 忽略功能函数
            ignore(span, index, value) {
                const id = span.dataset.id;
                const spanele = document.querySelector(`span[data-id="${id}"]`);
                this.spanEle = Array.from(this.spanEle);
                this.spanEle.splice(index, 1);
                spanele.style.removeProperty('background-color');
                spanele.style.removeProperty('transition');
                spanele.style.removeProperty('font-family');
                switch (span.classList[0]) {
                    case 'spell-err':
                        spanele.classList.remove('spell-err');
                        spanele.classList.add('spell-err-none');
                        this.spellErrCount--;
                        this.allspellCount--;
                        break;
                    case 'spell-err-high':
                        spanele.classList.remove('spell-err-high');
                        spanele.classList.add('spell-err-high-none');
                        this.spellErrHighCount--;
                        this.allspellCount--;
                        break;
                    // case 'spell-rep':
                    //     spanele.classList.remove('spell-rep')
                    //     spanele.classList.add('spell-rep-none')
                    //     this.spellRepCount--;
                    //     this.allspellCount--;
                    // break;
                    case 'spell-wrd-xmyin':
                        spanele.classList.remove('spell-wrd-xmyin');
                        spanele.classList.add('spell-wrd-xmyin-none');
                        this.spellwrdXmyinCount--;
                        this.allspellCount--;
                        break;
                    // case 'spell-rep-xm':
                    //     spanele.classList.remove('spell-rep-xm')
                    //     spanele.classList.add('spell-rep-xm-none')
                    //     this.spellrepXmCount--;
                    //     this.allspellCount--;
                    // break;
                    case 'spell-wrd-xm':
                        spanele.classList.remove('spell-wrd-xm');
                        spanele.classList.add('spell-wrd-xm-none');
                        this.spellwrdXmCount--;
                        this.allspellCount--;
                        break;
                    default:
                        break;
                }
                if (value !== 'null') {
                    this.ignored.push({ oldValue: value, index: index, element: span });
                } else {
                    return;
                }
            },
            // 撤回功能函数
            undo() {
                const lastIgnored = this.ignored.pop();
                const id = lastIgnored.element.dataset.id;
                const spanele = document.querySelector(`span[data-id="${id}"]`);
                if (spanele) {
                    const regex = /^[\u{e263}\u{202f}\u{180e}]/gu;
                    const testes = regex.test(spanele.innerText);
                    if (testes) {
                        if (spanele.previousSibling.nodeType === 3 && spanele.previousSibling.nodeName === '#text') {
                            spanele.previousSibling.textContent = this.preList.pop();
                        }
                    }
                    this.spanEle.splice(lastIgnored.index, 0, lastIgnored.element);
                    // lastIgnored.element.style.fontFamily = "ononDev";
                    switch (lastIgnored.element.classList[0]) {
                        case 'spell-err-none':
                            lastIgnored.element.classList.add('spell-err');
                            lastIgnored.element.classList.remove('spell-err-none');
                            if (lastIgnored.oldValue != '') {
                                lastIgnored.element.innerHTML = lastIgnored.oldValue;
                            }
                            // lastIgnored.element.scrollIntoView({behavior: 'smooth' ,inline : 'center'});
                            this.spellErr.splice(lastIgnored.index, 0, lastIgnored.element);
                            this.spellErrCount++;
                            this.allspellCount++;
                            break;
                        case 'spell-err-high-none':
                            lastIgnored.element.classList.add('spell-err-high');
                            lastIgnored.element.classList.remove('spell-err-high-none');
                            if (lastIgnored.oldValue != '') {
                                lastIgnored.element.innerHTML = lastIgnored.oldValue;
                            }
                            // lastIgnored.element.scrollIntoView({behavior: 'smooth' ,inline : 'center'});
                            this.spellErr.splice(lastIgnored.index, 0, lastIgnored.element);
                            this.spellErrHighCount++;
                            this.allspellCount++;
                            break;
                        // case 'spell-rep-none':
                        //     lastIgnored.element.classList.add('spell-rep');
                        //     lastIgnored.element.classList.remove('spell-rep-none');
                        //     if(lastIgnored.oldValue != ''){
                        //         lastIgnored.element.innerHTML = lastIgnored.oldValue
                        //     }
                        //     this.spellRep.splice(lastIgnored.index,0,lastIgnored.element);
                        //     this.spellRepCount++;
                        //     this.allspellCount++;
                        // break;
                        case 'spell-wrd-xmyin-none':
                            lastIgnored.element.classList.add('spell-wrd-xmyin');
                            lastIgnored.element.classList.remove('spell-wrd-xmyin-none');
                            if (lastIgnored.oldValue != '') {
                                lastIgnored.element.innerHTML = lastIgnored.oldValue;
                            }
                            // lastIgnored.element.scrollIntoView({behavior: 'smooth' ,inline : 'center'});
                            this.spellwrdXmyin.splice(lastIgnored.index, 0, lastIgnored.element);
                            this.spellwrdXmyinCount++;
                            this.allspellCount++;
                            break;
                        // case 'spell-rep-xm-none':
                        //     lastIgnored.element.classList.add('spell-rep-xm');
                        //     lastIgnored.element.classList.remove('spell-rep-xm-none');
                        //     if(lastIgnored.oldValue != ''){
                        //         lastIgnored.element.innerHTML = lastIgnored.oldValue
                        //     }
                        //     this.spellrepXm.splice(lastIgnored.index,0,lastIgnored.element);
                        //     this.spellrepXmCount++;
                        //     this.allspellCount++;
                        // break;
                        case 'spell-wrd-xm-none':
                            lastIgnored.element.classList.add('spell-wrd-xm');
                            lastIgnored.element.classList.remove('spell-wrd-xm-none');
                            if (lastIgnored.oldValue != '') {
                                lastIgnored.element.innerHTML = lastIgnored.oldValue;
                            }
                            // lastIgnored.element.scrollIntoView({behavior: 'smooth' ,inline : 'center'});
                            this.spellwrdXm.splice(lastIgnored.index, 0, lastIgnored.element);
                            this.spellwrdXmCount++;
                            this.allspellCount++;
                            break;
                        default:
                            break;
                    }
                }
            },
            // 监听校对区域li元素鼠标进入
            handleMouseEnter(e) {
                let id = e.target.dataset.id;
                let span = document.querySelector(`span[data-id="${id}"]`);
                this.liTimer = setTimeout(() => {
                    if (span) {
                        switch (span.className) {
                            case 'spell-err':
                                span.style.backgroundColor = 'rgba(153,0,0,.26)';
                                span.style.transition = 'all .4s ease';
                                span.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                break;
                            case 'spell-err-high':
                                span.style.backgroundColor = 'rgba(0, 153, 255,.26)';
                                span.style.transition = 'all .4s ease';
                                span.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                break;
                            // case 'spell-rep':
                            //     span.style.backgroundColor = "rgba(255, 0, 0,.26)";
                            //     span.style.transition = "all .4s ease";
                            //     span.scrollIntoView({behavior: 'smooth' , block : 'center'})
                            // break;
                            case 'spell-wrd-xmyin':
                                span.style.backgroundColor = 'rgba(0, 153, 0,.26)';
                                span.style.transition = 'all .4s ease';
                                span.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                break;
                            // case 'spell-rep-xm':
                            //     span.style.backgroundColor = "rgba(0, 153, 255,.26)";
                            //     span.style.transition = "all .4s ease";
                            //     span.scrollIntoView({behavior: 'smooth' , block : 'center'})
                            // break;
                            case 'spell-wrd-xm':
                                span.style.backgroundColor = 'rgba(255, 153, 0,.26)';
                                span.style.transition = 'all .4s ease';
                                span.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                break;
                            default:
                                break;
                        }
                    }
                }, 100);
                if (e.target.children[0].scrollWidth > e.target.scrollWidth) {
                    e.target.children[0].style.width = e.target.children[0].scrollWidth + 'px';
                }
            },
            // 监听校对区域li元素鼠标离开
            handleMouseLeave(e) {
                clearTimeout(this.liTimer);
                const id = e.target.dataset.id;
                const span = document.querySelector(`span[data-id="${id}"]`);
                span.style.removeProperty('background-color');
                span.style.removeProperty('transition');
                this.open = false;
                e.target.children[0].style.width = 52 + 'px';
            },
            // 判断候选词区域li元素class
            liClassName(e) {
                switch (e.classList[0]) {
                    case 'spell-err':
                        return 'spellErr';
                        break;
                    case 'spell-err-high':
                        return 'spellErrHigh';
                        break;
                    case 'spell-wrd-xmyin':
                        return 'spellwrdXmyin';
                        break;
                    case 'spell-wrd-xm':
                        return 'spellwrdXm';
                        break;
                    default:
                        break;
                }
            },
            // 判断候选词区域li元素子元素class
            slstSpanClassName(e) {
                switch (e.classList[0]) {
                    case 'spell-err':
                        return 'spellErrSpan';
                        break;
                    case 'spell-err-high':
                        return 'spellErrHighSpan';
                        break;
                    // case 'spell-rep':
                    //     return 'spellRepSpan'
                    // break;
                    case 'spell-wrd-xmyin':
                        return 'spellwrdXmyinSpan';
                        break;
                    // case 'spell-rep-xm':
                    //     return 'spellrepXmSpan'
                    // break;
                    case 'spell-wrd-xm':
                        return 'spellwrdXmSpan';
                        break;
                    default:
                        break;
                }
            },
            // 筛选span元素属性值
            spanAttribute(e) {
                let d = { old: '', high: '', slst: '' };
                let old = e.getAttribute('old-wrd-data');
                let high = e.getAttribute('high-p-wrd');
                let slst = e.getAttribute('slst-data');
                if (old) {
                    d.old = old;
                } else {
                    d.old = e.innerText;
                }
                if (high) {
                    d.high = high;
                } else {
                    d.high = '';
                }
                if (slst) {
                    d.slst = slst.split(',');
                } else {
                    d.slst = '';
                }
                switch (e.classList[0]) {
                    case 'spell-err':
                        return { type: 'spell-err', data: d };
                        break;
                    case 'spell-err-high':
                        return { type: 'spell-err-high', data: d };
                        break;
                    // case 'spell-rep':
                    //     return {type:'spell-rep',data:d}
                    // break;
                    case 'spell-wrd-xmyin':
                        return { type: 'spell-wrd-xmyin', data: d };
                        break;
                    // case 'spell-rep-xm':
                    //     return {type:'spell-rep-xm',data:d}
                    // break;
                    case 'spell-wrd-xm':
                        return { type: 'spell-wrd-xm', data: d };
                        break;
                    default:
                        break;
                }
            },
            // 候选词确定
            setWord(span, value, index) {
                const regex = /^[\u{e263}\u{202f}\u{180e}]/gu;
                const testes = regex.test(value);
                const id = span.dataset.id;
                const spanElement = document.querySelector(`span[data-id="${id}"]`);
                let oldText = spanElement.innerHTML;
                spanElement.innerHTML = value;
                if (testes) {
                    let previous = spanElement.previousSibling;
                    if (previous.nodeType === 3 && previous.nodeName === '#text') {
                        this.preList.push(previous.textContent);
                        const preRegex = /\s+$/;
                        const preMatches = previous.textContent.replace(preRegex, '');
                        previous.textContent = preMatches;
                    }
                }
                this.ignore(span, index, oldText);
            },
            // 分类按钮功能
            sortClick(type) {
                switch (type) {
                    case 'all':
                        this.errType = { all: true, spellErr: false, spellErrHigh: false, spellwrdXmyin: false, spellwrdXm: false };
                        if (this.spellErrHighCount == 0) {
                            this.imgUrl = require('@/assets/images/Prompt.png');
                        }
                        break;
                    case 'spellErr':
                        this.errType.all = false;
                        this.errType.spellErr = !this.errType.spellErr;
                        break;
                    case 'spellErrHigh':
                        this.errType.all = false;
                        this.errType.spellErrHigh = !this.errType.spellErrHigh;
                        break;
                    case 'spellwrdXmyin':
                        this.errType.all = false;
                        this.errType.spellwrdXmyin = !this.errType.spellwrdXmyin;
                        break;
                    case 'spellwrdXm':
                        this.errType.all = false;
                        this.errType.spellwrdXm = !this.errType.spellwrdXm;
                        break;
                }
                if (!this.errType.spellErr && !this.errType.spellErrHigh && !this.errType.spellwrdXmyin && !this.errType.spellwrdXm) {
                    this.errType.all = true;
                }
                this.sortFor();
                // localStorage.setItem('buttonState', JSON.stringify(this.errType));
            },
            // 分类按钮功能
            sortFor() {
                let a = this.spanEle;
                for (let i = 0; i < a.length; i++) {
                    let id = a[i].dataset.id;
                    if (this.errType.all) {
                        a[i].dataset.view = true;
                        a[i].classList.remove('span-none');
                    } else {
                        a[i].dataset.view = false;
                        a[i].classList.add('span-none');
                    }
                    if (this.errType.spellErr) {
                        if (a[i].classList[0] == 'spell-err') {
                            a[i].dataset.view = true;
                            a[i].classList.remove('span-none');
                        }
                    }
                    if (this.errType.spellErrHigh) {
                        if (a[i].classList[0] == 'spell-err-high') {
                            a[i].dataset.view = true;
                            a[i].classList.remove('span-none');
                        }
                    }
                    if (this.errType.spellwrdXmyin) {
                        if (a[i].classList[0] == 'spell-wrd-xmyin') {
                            a[i].dataset.view = true;
                            a[i].classList.remove('span-none');
                        }
                    }
                    if (this.errType.spellwrdXm) {
                        if (a[i].classList[0] == 'spell-wrd-xm') {
                            a[i].dataset.view = true;
                            a[i].classList.remove('span-none');
                        }
                    }
                }
                this.$forceUpdate();
                this.spanEle = a;
            },
            firstToken() {
                let url = '/onon/openapi/auth/token';
                axios
                    .get(
                        'https://user.onon.cn' +
                            url +
                            '?code=' +
                            this.form.code +
                            // '&grant_type=authorization_code&response_type=code&client_id=1777507618860961793&client_secret=0f77ac2fbbd249dfb3f5fd1714196d7b&redirect_uri=http://localhost:8080/'
                            '&grant_type=authorization_code&response_type=code&client_id=1729390517613105152&client_secret=d9914cf630f74d81825ada82e27186b5&redirect_uri=http://spell.onon.cn'
                    )
                    .then((res) => {
                        if (res.data.code == 200) {
                            this.token = res.data.result.access_token;
                            window.localStorage.setItem('access_token', res.data.result.access_token);
                            this.getUser();
                        } else {
                            this.$message.error(res.data.message);
                            this.clearRouter();
                            this.login = true;
                            window.localStorage.clear();
                        }
                    });
            },
            getUser() {
                let div = document.getElementById('div');
                let url = '/onon/openapi/auth/user';
                let access_token = window.localStorage.getItem('access_token');
                axios.get('https://user.onon.cn' + url, { params: { access_token: access_token } }).then((res) => {
                    if (res.data.code == 40002) {
                        this.$Message.info(res.data.message);
                        window.localStorage.clear();
                        this.clearRouter();
                        this.login = false;
                    } else if (res.data.code == 40003) {
                        this.$Message.error(res.data.message);
                        window.localStorage.clear();
                        this.login = false;
                        this.clearRouter();
                    } else if (res.data.code == 40001) {
                        window.localStorage.clear();
                        this.login = false;
                        this.clearRouter();
                        this.getCookie();
                    } else if (res.data.code == 200) {
                        this.user = res.data.result;

                        // this.$Message.success("登录成功")
                        this.clearRouter();
                        this.login = true;
                        this.getServiceDetails();
                        console.log(200);
                        //统计userid
                        var _paq = (window._paq = window._paq || []);
                        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                        _paq.push(['setExcludedQueryParams', ['code', 'state', 'accessToken', 'xfrom']]);
                        _paq.push(['trackPageView']);
                        _paq.push(['enableLinkTracking']);
                        var this_ = this;
                        var u = '//analyze.onon.cn/';
                        _paq.push(['setTrackerUrl', u + 'matomo.php']);
                        _paq.push(['setSiteId', '8']);

                        //提交userid
                        if (this_.login) {
                            var uid = this_.user.userId;
                            _paq.push(['setUserId', uid]);
                        }

                        var d = document,
                            g = d.createElement('script'),
                            s = d.getElementsByTagName('script')[0];
                        g.async = true;
                        g.src = u + 'matomo.js';
                        s.parentNode.insertBefore(g, s);
                        // if(window.localStorage.getItem('text')){
                        //     div.innerText = window.localStorage.getItem('text')
                        //     this.contentChange(div)
                        //     window.localStorage.removeItem('text')
                        //     this.isCopy = true
                        //     this.isDelete = true
                        //     this.imageShow = false
                        //     // this.clip(div)
                        // }
                    } else {
                        this.$Message.error('用户信息获取失败！');
                    }
                });
            },
            getServiceDetails() {
                let url = 'https://user.onon.cn/onon/openapi/getGrantServiceCallCount';
                let data = { userId: this.user.userId, svid: '3' };
                let config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
                axios.post(url, data, config).then((res) => {
                    this.ServiceDetails = res.data.result.canCount;
                    this.ServiceTotalDetails = res.data.result.totalCount;
                    this.baifenbi(this.ServiceDetails, this.ServiceTotalDetails);
                });
            },
            clearRouter() {
                if (this.$route.query.code || this.$route.query.accessToken) {
                    //清空地址栏路由参数
                    this.$router.push({
                        query: {}
                    });
                }
            },
            getCookie() {
                //判断cookie中是否有token
                let cookieToken = cookie.get('SSOSYSaccessToken');
                if (cookieToken) {
                    let url = 'https://user.onon.cn/onon/openapi/auth/checkAuth';
                    let from = new FormData();
                    from.append('accessToken', cookieToken);
                    axios.post(url, from).then((res) => {
                        if (res.data.code == 200) {
                            let url = 'https://user.onon.cn/onon/openapi/auth/authorized';
                            let data = { client_id: '1729390517613105152', redirect_uri: 'http://spell.onon.cn', state: '1234', accessToken: cookieToken };
                            axios({
                                method: 'post',
                                url: url,
                                data: data,
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                }
                            }).then((res) => {
                                if (res.data.success) {
                                    this.form.code = res.data.result.code;
                                    this.firstToken();
                                }
                            });
                        } else if (res.data.code == 401) {
                            this.login = false;
                            // cookie.remove('SSOSYSaccessToken',{
                            //     domain: 'onon.cn',
                            //     path: '/'
                            // })
                        } else {
                            this.$Message.error(res.data.message);
                        }
                    });
                } else {
                    this.$Message.info(res.data.message);
                }
            },
            getPcImg() {
                let activateUrl = '/onon/setting/activate';
                axios.get('https://user.onon.cn' + activateUrl).then((res) => {
                    if (res.data.result.position == 'ALL' || (res.data.result.position == 'JIAODUI' && res.data.result.open == true)) {
                        this.activateOpen = res.data.result.open;
                        this.pcImg = res.data.result.pcimg;
                        console.log(111);

                        this.href = res.data.result.href;
                    } else {
                        this.pcImg = '';
                    }
                });
            }
        },
        mounted() {
            let _this = this;
            _this.getPcImg();
            // console.log('oldText是', _this.oldText);
            const queryString = window.location.search; // 获取查询字符串
            const params = new URLSearchParams(queryString); // 创建URLSearchParams对象
            _this.form.code = params.get('code'); // 链接中的code
            // let token = params.get('accessToken') // 链接中的token
            let getToken = window.localStorage.getItem('accessToken'); // 缓存中的token
            if (_this.form.code) {
                // window.localStorage.setItem('accessToken',token)
                _this.firstToken();
            } else {
                if (window.localStorage.getItem('access_token')) {
                    _this.getUser();
                    _this.login = true;
                } else {
                    _this.login = false;
                    //判断cookie中是否有token
                    _this.getCookie();
                }
            }
            // if(_this.isMobile()){
            //     console.log('手机端');
            //     _this.deviceTypeModal = true
            // }else{
            //     console.log('pc端');
            // }
            // 鼠标横向滚动优化
            $(document).ready(function () {
                let wrapper = $('.word-container');
                let wordTip = $('.word-tip');
                $(wrapper).on('mousewheel', function (event) {
                    event.preventDefault();
                    let scrollLeft = $(this).scrollLeft();
                    $(this).scrollLeft(scrollLeft + event.originalEvent.deltaY * 1.5);
                });
                $(wordTip).on('mousewheel', function (event) {
                    event.preventDefault();
                    let scrollLeft = $(this).scrollLeft();
                    $(this).scrollLeft(scrollLeft + event.originalEvent.deltaY * 2);
                });
            });
            // wordList组件鼠标点击消失
            document.addEventListener('click', function () {
                if (_this.open) {
                    _this.open = false;
                }
            });
            // 监听用户复制文本事件
            document.addEventListener('copy', function (event) {
                event.stopPropagation();
                let selectedText = window.getSelection().toString();
                if (selectedText) {
                    let url = 'http://39.99.62.216:9001/jna/saveChangedWords';
                    // let url = 'http://11.0.0.43:8081/jna/saveChangedWords'
                    let data = { ChangedWords: selectedText };
                    axios.post(url, data);
                }
            });
            // let editor = document.getElementById('div');
            document.addEventListener('keydown', function (e) {
                if (e.code == 'KeyZ') {
                    if (_this.ignored.length != 0) {
                        _this.undo();
                    }
                }
            });
        }
    };
</script>

<style lang="less">
    @import './Admin.less';
</style>
